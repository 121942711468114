import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaFolderOpen } from 'react-icons/fa'; // Importing the icon
import ImageComponent from '../../../Features/ImageComponent';
//import baseUrl from '../../../services/axiosConfig.ts';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

const WorkshopTable = ({ formSubmitted }) => {
  const [workshopList, setWorkshopList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAllPosts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/workshops`);
        setWorkshopList(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Axios network error: ", err);
        setLoading(false);
      }
    };
    getAllPosts();
  }, [formSubmitted]);

  // Function to handle delete
  const handleDelete = async (workshopId) => {
    const userConfirmed = window.confirm(
      'Are you sure you want to delete this workshop? This action cannot be undone.'
    );

    if (userConfirmed) {
      try {
        // Making the DELETE request
        await axios.delete(`${baseUrl}/workshops/${workshopId}`);

        // Removing the deleted item from the state
        setWorkshopList((prevWorkshops) =>
          prevWorkshops.filter((workshop) => workshop.id !== workshopId)
        );

        // Optionally, you can display a success message or toast
        alert('Workshop deleted successfully.');
      } catch (err) {
        console.error('Error deleting the workshop:', err);
        alert('Failed to delete the workshop. Please try again.');
      }
    }
  };

  return (
    <div className="bg-white container mx-auto p-4 rounded-lg">
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-500"></div>
          <span className="ml-2 text-gray-500">Loading workshops...</span>
        </div>
      ) : workshopList.length === 0 ? (
        <div className="flex justify-center items-center flex-col h-64">
          <FaFolderOpen className="text-6xl text-gray-400 mb-4" />
          <span className="text-gray-500">No workshops available</span>
        </div>
      ) : (
        <>
          <table className="table-auto w-full border-collapse border border-gray-300 hidden md:table">
            <thead>
              <tr>
                <th className="border border-gray-300 p-2 text-left">Description</th>
                <th className="border border-gray-300 p-2 text-left">Images</th>
                <th className="border border-gray-300 p-2 text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {workshopList.map((workshop) => (
                <tr key={workshop.id}>
                  <td className="border border-gray-300 p-2">{workshop.description}</td>
                  <td className="border border-gray-300 p-2">
                    <div className="flex space-x-2">
                      {workshop.workshopImages.map((image) => (
                        <ImageComponent
                          key={image.id}
                          src={image.url}
                          alt={image.name}
                          className="w-16 h-16 object-cover rounded"
                        />
                      ))}
                    </div>
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    <button
                      onClick={() => handleDelete(workshop.id)}
                      className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="block md:hidden">
            {workshopList.map((workshop) => (
              <div key={workshop.id} className="border border-gray-300 mb-4 p-4 rounded-lg">
                <div className="flex flex-col">
                  <div className="mb-2">
                    <span className="font-bold text-gray-700">Description:</span>
                    <p>{workshop.description}</p>
                  </div>

                  <div className="mb-2">
                    <span className="font-bold text-gray-700">Images:</span>
                    <div className="flex flex-wrap -m-2 mt-2">
                      {workshop.workshopImages.map((image) => (
                        <div key={image.id} className="m-2">
                          <img
                            src={image.url}
                            alt={image.name}
                            className="w-16 h-16 object-cover rounded"
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <span className="font-bold text-gray-700">Action:</span>
                    <div className="mt-2">
                      <button
                        onClick={() => handleDelete(workshop.id)}
                        className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default WorkshopTable;
