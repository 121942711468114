import React, { useEffect, useState } from 'react'; 
import { Blurhash } from 'react-blurhash';

export default function ImageComponent({ src, alt, blurhash, className, onClick }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(()=>{
    const img = new Image();
    img.onload = () => {
        setImageLoaded(true);
    };
    img.src = src;
  }, [src]);    

  return(
    <>
      <div 
        className={className}  
        style={{
          position: 'relative', 
        }}
      >
        {/* Blurhash shown while the image is loading */}
        <div 
          style={{
            position: 'absolute', 
            top: 0, 
            left: 0, 
            right: 0, 
            bottom: 0, 
            display: imageLoaded ? 'none' : 'block'
          }}
        >
          <Blurhash
            hash={blurhash || "LEHV6nWB2yk8pyo0adR*.7kCMdnj"}
            width="100%"
            height="100%"
            resolutionX={32}
            resolutionY={32}
            punch={1}
          />
        </div>
        
        {/* Image shown after it loads */}
        <img 
          src={src} 
          alt={alt} 
          style={{
            width: '100%', 
            height: '100%', 
            objectFit: 'cover',  // Ensures the image covers the entire container
            display: imageLoaded ? 'block' : 'none'
          }}
          onClick={onClick}
        />
      </div>
    </>
  );
}
