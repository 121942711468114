import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from "../../Navigation/Navbar";
import Footer from "../../Footer/Footer";
import ImageComponent from '../../../Features/ImageComponent';
import ImageModal from './ImageModal';  
import { FaExclamationCircle } from 'react-icons/fa'; 
//import baseUrl from '../../../services/axiosConfig.ts';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

const Workshop = () => {
  const [workshopList, setWorkshopList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (url) => {
    setSelectedImage(url); // Set the selected image URL when clicked
  };

  const isMobile = () => window.innerWidth <= 768; // Adjust the width as necessary

  useEffect(() => {
    const getAllPosts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/workshops`);
        setWorkshopList(response.data); // Set data if successful
        setError(false); // No error
      } catch (err) {
        console.error("Axios network error: ", err); // Log the error
        setError(true); // Set error to true
      }
    };
    getAllPosts();
    
    // Force the loader to remain visible for at least 1 minute (60,000 ms)
    const minimumLoadTime = setTimeout(() => {
      setIsLoading(false); // Hide loader after 1 minute
    }, 2000);

    return () => clearTimeout(minimumLoadTime); // Clear the timer on component unmount
  }, []);


  return (
    <div className="bg-[#e0e0e0] overflow-x-hidden">
      <Navbar />
      {/* Loader */}
      {isLoading && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-90 flex items-center justify-center">
          <div className="flex flex-col items-center text-white text-2xl font-bold">
            <div className="loader animate-spin rounded-full border-t-4 border-white w-16 h-16 mb-4"></div>
            <div className="mx-auto text-center">Loading Images...</div>
          </div>
        </div>
      )}

      <section className="pt-20 mx-4 md:px-32 md:mt-32 pb-10 lg:pt-[30px] lg:pb-2 bg-[#e0e0e0]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap justify-center">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
                <h2 className="text-dark mb-4 text-xl font-bold sm:text-4xl md:text-[40px]">
                  Our Recent Transformative and Power-packed Training Sessions
                </h2>
                <p className="text-body-color text-base">
                  Our latest TaRL pilot training have focused on empowering teachers with practical tools to support student progress in literacy and numeracy.
                </p>
              </div>
            </div>
          </div>

          {/* Check for error */}
          {error ? (
            <div className="text-center text-red-500">
              Could not load image data. Please try again later.
            </div>
          ) : workshopList.length === 0 ? (
            /* If the workshopList is empty, show the icon and message */
            <div className="flex flex-col items-center justify-center text-gray-500 text-center">
              <FaExclamationCircle className="text-6xl mb-4" />
              <p className="text-xl">No workshops available at the moment.</p>
            </div>
          ) : (
            /* Loop through the workshopList */
            <div className="-mx-4 flex flex-wrap">
              {workshopList.map((workshop, index) => (
                <div key={workshop.id} className="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div className="mx-auto mb-10 max-w-[670px]">
                    {/* Workshop Title and Date */}
                    <div className="text-center mb-4">
                      <h3 className="text-dark text-xl font-semibold sm:text-2xl lg:text-xl xl:text-2xl">
                        {workshop.description}
                      </h3>
                      <p className="text-gray-500 text-sm">{workshop.dateOfWorkshop}</p>
                    </div>

                    {/* Workshop Images */}
                    <div className="mb-8 overflow-hidden rounded">
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        {workshop.workshopImages.map((image) => (
                          <div key={image.id} className="overflow-hidden rounded">
                            <ImageComponent
                              src={image.url}
                              alt={image.name}
                              className="w-[385px] h-[225px] md:w-[245px] md:h-[135px] object-cover cursor-pointer"
                              onClick={!isMobile() ? () => handleImageClick(image.url) : null} // Disable onClick for mobile
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Image Modal */}
      <ImageModal imageUrl={selectedImage} onClose={() => setSelectedImage(null)} />
      </section>
      <Footer/>
    </div>
  );
};

export default Workshop;