import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa'; // Import the React icon
import Navbar from "../../Navigation/Navbar";
import Footer from "../../Footer/Footer";

const NotFound = () => {
  return (
    <div>
        <Navbar />
        <div className="flex flex-col items-center justify-center min-h-screen bg-[#e0e0e0]">
            {/* Icon */}
            <FaExclamationTriangle className="text-red-600 text-6xl mb-4" />
            
            {/* 404 Text */}
            <h1 className="text-4xl font-bold text-gray-800 mb-2">Page Not Found</h1>
            
            {/* Description */}
            <p className="text-gray-600 text-lg text-center">
                Oops! The page you are looking for was not found.
            </p>
        </div>
        <Footer/>
    </div>
  );
};

export default NotFound;
