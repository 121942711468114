import React from "react";
import Navbar from "../../Navigation/Navbar";
import Features from "../../../Features/Features";
import CallToAction from "../../CallToAction/CallToAction";
import Footer from "../../Footer/Footer";
import Banner from "../../CallToAction/Banner";

const Homepage = () => {
  return (
    <>
     <div className="overflow-x-hidden">
        <Navbar />
        <Banner/>
        <Features/>
        <CallToAction/>
        <Footer/>
     </div>
    </>
  );
};

export default Homepage;
