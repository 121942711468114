import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Homepage from '../src/Components/Pages/Homepage/Homepage'
import About from '../src/Components/Pages/About/About'
import Services from '../src/Components/Pages/Services/Services'
import Contact from '../src/Components/Pages/Contact/Contact'
import DashboardPage from '../src/Components/Pages/Dashboard/DashboardPage'
import './App.css';
import Workshop from './Components/Pages/Workshop/Workshop'
import NotFound from './Components/Pages/NotFound/NotFound'
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/gallery" element={<Workshop />} />
        <Route path="*" element={<NotFound />} /> {/* 404 route */}
      </Routes>
      {/* Include ToastContainer to render the toasts */}
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
