import React from 'react'
import {FaFacebookSquare, FaInstagram, FaTwitterSquare, FaDribbbleSquare, FaGithubSquare} from 'react-icons/fa'

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="overflow-x-hidden max-w-screen font-poppins">
      {/* footer top section */}
      <div className="flex flex-col sm:flex-col md:flex-row justify-evenly items-center md:pl-8 py-8 px-6 bg-gray-900">
        <div className="text-white flex-1 mb-20 md:my-12 sm:px-4 ">
          <h1 className="text-3xl font-bold cursor-pointer text-center text-blue-400">
          Dreams Anchor Institute
          </h1>
          <p className=" text-justify">
            Welcome to Dreams Anchor Institute, a non-profit organization dedicated to improving the literacy and numeracy skills of early-grade learners in underserved primary schools across Ghana. We are passionate about providing foundational educational opportunities to children, unlocking their potential, and ensuring they receive the right tools to thrive. Join us in transforming education for a brighter future!
          </p>
          <div className="flex justify-evenly pt-8 md:w-[90%] text-blue-200">
            {/* social media icons */}
            <FaFacebookSquare size={30} className="cursor-pointer " />
            <FaDribbbleSquare size={30} className="cursor-pointer " />
            <FaInstagram size={30} className="cursor-pointer " />
            <FaGithubSquare size={30} className="cursor-pointer " />
            <FaTwitterSquare size={30} className="cursor-pointer " />
          </div>
        </div>
        <div className="text-white flex-1 md:">
            <h1 className=" md:text-3xl text-center text-2xl font-bold">
              Want tips on how to assist our work flow?
            </h1>
            <p className=" text-center pb-2">
              Sign up to our newsletter and stay up to date.
            </p>
            <div>
              <div className="flex justify-center items-center">
                <input
                  className="placeholder:pl-2 w-[60%] lg:w-[45%] h-10 md:h-[35px] rounded-md text-black mr-4"
                  type="email"
                  placeholder="Enter Your E-Mail"
                />
                <button className="bg-gradient-to-r from-emerald-400 via-lime-400 to-emerald-400 w-28 h-10 md:h-[35px] hover:from-orange-500 hover:via-pink-500 hover:to-yellow-500 rounded-md font-medium">
                  Sign UP
                </button>
              </div>
              <p className="text-sm mx-10 flex-col text-center pt-4">
                We care about the protection of your data. Read our{" "}
                <span className="underline cursor-pointer text-blue-400">
                  Privacy Policy
                </span>
              </p>
            </div>

        </div>
      </div>
      {/* footer top section end */}
      <div className="text-gray-300 bg-gray-800 ">
        <div className="h-[1px] bg-gray-100/20 w-[100rem] "></div>
        <p className='py-4 text-gray-200/40 text-sm flex justify-center'>All Rights Reserved {currentYear} - Dreams Anchor Institute</p>
      </div>
      {/* footer bottom section with links */}
      <div></div>
      {/* footer bottom section with links end */}
    </div>
  );
}

export default Footer