import logo1 from "../../src/images/Features/f1.png";
import logo2 from "../../src/images/Features/f2.png";
import logo3 from "../../src/images/Features/f3.png";
import logo4 from "../../src/images/Features/f4.png";
import logo5 from "../../src/images/Features/f5.png";
import logo6 from "../../src/images/Features/f6.png";
import edu2 from "../../src/images/absedu2.png";
import edu3 from "../../src/images/absedu3.png";
import edu4 from "../../src/images/absedu4.png";
import ImageComponent from "./ImageComponent";

const Features = () => {
  return (
    <>
      <div className="max-w-full overflow-x-hidden bg-[#e0e0e0] font-poppins">
        {/* features section */}
        <>
          <section className="text-black">
            <div className="flex flex-col mx-4 md:mt-12 md:mx-32 md:flex-row gap-4 lg:mx-64">
              <div className="flex flex-col md:flex-row items-center bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                <ImageComponent
                  className="w-full md:w-48 h-96 md:h-full object-cover rounded-t-lg md:rounded-none md:rounded-l-lg"
                  src={edu3}
                  alt=""
                />
                <div className="flex flex-col justify-between p-4 leading-normal">
                  <h5 className="mb-2 text-xl text-center font-bold tracking-tight text-gray-900 dark:text-white">
                    Teaching at the Right Level (TaRL)
                  </h5>
                  <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    At the heart of our work is the Teaching at the Right Level (TaRL) methodology, a proven, child-centered approach that helps students improve their foundational reading and math skills.
                  </p>
                </div>
              </div>

              <div className="flex flex-col md:flex-row-reverse items-center bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                <ImageComponent
                  className="w-full md:w-48 h-96 md:h-full object-cover rounded-t-lg md:rounded-none md:rounded-r-lg"
                  src={edu2}
                  alt=""
                />
                <div className="flex flex-col justify-between p-4 leading-normal">
                  <h5 className="mb-2 text-xl text-center font-bold tracking-tight text-gray-900 dark:text-white"> </h5>
                  <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    This approach focuses on assessing each child's current level of understanding and providing targeted support to help them catch up and progress in their learning.
                  </p>
                </div>
              </div>
            </div>

            <div className="container max-w-xl w-full p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl overflow-hidden">
              <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                <div>
                  <h3 className="text-2xl font-bold tracking-tight sm:text-3xl">
                    Literacy and Numeracy Program
                  </h3>
                  <p className="mt-3 text-lg">
                    We partner with schools, teachers, and local education authorities to ensure our programs align with national standards and make a lasting impact.
                  </p>
                  <div className="mt-12 space-y-12">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center w-12 h-12 rounded-md dark:text-gray-900">
                          <img src={logo1} alt="logo1" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <h4 className="text-lg font-medium leading-6">
                          Advanced Mentorship Solutions
                        </h4>
                        <p className="mt-2">
                          We provide extensive training and support to teachers in participating schools, empowering them with the tools and methodologies they need to effectively implement TaRL and engage learners. Ongoing coaching and mentoring ensure continuous improvement in their teaching strategies.
                        </p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center w-12 h-12 rounded-md dark:text-gray-900">
                          <img src={logo2} alt="logo2" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <h4 className="text-lg font-medium leading-6">
                          Making a Difference
                        </h4>
                        <p className="mt-2">
                          Since launching our pilot projects in five underserved schools, Dreams Anchor Institute has already seen impressive improvements in the literacy and numeracy skills of participating students. Through our interventions, we aim to bridge the educational gap for thousands of children across Ghana.
                        </p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center w-12 h-12 rounded-md dark:text-gray-900">
                          <img src={logo3} alt="logo3" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <h4 className="text-lg font-medium leading-6">
                          Trained Personnel Support
                        </h4>
                        <p className="mt-2">
                          Our trained experts ensure smooth teaching and ongoing guidance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div aria-hidden="true" className="mt-10 lg:mt-0">
                  <ImageComponent
                    src={edu4}
                    alt="image1"
                    className="w-full md:w-full h-96 md:h-full object-cover rounded"
                  />
                </div>
              </div>

              <div>
                <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                  <div className="lg:col-start-2">
                    <h3 className="text-2xl font-bold tracking-tight sm:text-3xl">
                      Transformable Decisions
                    </h3>
                    <p className="mt-3 text-lg">
                      Transform the weakest learners into confident, capable individuals who can read English fluently and tackle Math problems with ease.
                    </p>
                    <div className="mt-12 space-y-12">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 rounded-md dark:text-gray-900">
                            <img src={logo4} alt="logo4" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg font-medium leading-6">
                            Powerful Analytics Capabilities
                          </h4>
                          <p className="mt-2">
                            Extract valuable insights from data with comprehensive visualizations. Monitor KPIs, track trends, and identify areas for improvement of learner.
                          </p>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 rounded-md dark:text-gray-900">
                            <img src={logo5} alt="logo5" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg font-medium leading-6">
                            Personalized Solutions
                          </h4>
                          <p className="mt-2">
                            Tailored software solutions to address unique learner needs. Achieve sustainable growth by aligning with feasible objectives.
                          </p>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 rounded-md dark:text-gray-900">
                            <img src={logo6} alt="logo6" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg font-medium leading-6">
                            Continuous Innovation and Upgrades
                          </h4>
                          <p className="mt-2">
                            Empower even the most struggling learners to overcome challenges and excel in their studies, and gain a competitive edge through continuous innovation.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
                    <ImageComponent
                      src={edu2}
                      alt="image1"
                      className="w-full md:w-full h-96 md:h-full object-cover rounded-lg"
                    />
                  </div>
                </div>
              </div>
            </div>

          </section>
        </>
        {/* end */}
      </div>
    </>
  );
};

export default Features;
