import React from 'react';

const ImageModal = ({ imageUrl, onClose }) => {
  if (!imageUrl) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-80 flex items-center justify-center">
      <div className="relative">
        <button
          className="absolute top-2 right-2 text-white text-2xl"
          onClick={onClose}
        >
          &times;
        </button>
        {/* Restrict the image size */}
        <img 
          src={imageUrl} 
          alt="Full Size" 
          className="w-[850px] h-[525px] object-cover rounded-lg" 
        />
      </div>
    </div>
  );
};

export default ImageModal;
