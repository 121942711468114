import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import FaAccusoftX from "../../images/logo512.png";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  console.log(open);
  return (
    <div className="max-w-screen-xl overflow-x-hidden font-poppins">
      <nav className="bg-gradient-to-r from-gray-900 via-emerald-600 to-gray-900 fixed px-2  sm:h-auto sm:w-full w-screen z-20 left-0 right-0 top-0 border-b border-gray-400">
        {/* dektop nav */}
        <div className="max-w-screen-xl flex flex-wrap items-center mx-auto justify-between sm:p-4 pb-2">
          <NavLink to="/" className="flex items-center">
            {/*<img
              src={FaEdit}
              alt="icon"
              className="hidden sm:block sm:w-[35px] sm:-h-[35px] "
            />*/}
            <img src={FaAccusoftX} alt="" className="hidden sm:block sm:w-[45px] sm:h-[45px]" />
            <span className="ml-2 mt-2 sm:mt-0 text-white font-semibold text-xl">
              Dreams Anchor
            </span>
          </NavLink>
          <div className="flex">
            <ul className="sm:flex justify-center hidden">
              <li className="p-2 mr-4 text-white hover:text-cyan-800 cursor-pointer">
                <NavLink 
                  to="/"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                  end
                >
                  Home
                </NavLink>
              </li>
              <li className="p-2 mr-4 text-white hover:text-cyan-800 cursor-pointer">
                <NavLink
                 to="/about"
                 className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                  About
                </NavLink>
              </li>
              <li className="p-2 text-white hover:text-cyan-800 cursor-pointer">
                <NavLink 
                  to="/contact"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                  Contact
                </NavLink>
              </li>
              <li className="p-2 text-white hover:text-cyan-800 cursor-pointer">
                <NavLink 
                  to="/gallery"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                  Gallery
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="flex">
            <button
              type="button"
              className="bg-gradient-to-r from-emerald-400 via-lime-400 to-emerald-400   px-4 py-2 text-white hidden sm:block rounded mr-2 hover:from-orange-500 hover:via-pink-500 hover:to-yellow-500"
            >
              DONATE
            </button>
            <button onClick={() => setOpen(!open)} className="sm:hidden block">
              <GiHamburgerMenu size={30} className="mt-2 mr-2 text-white/70" />
            </button>
          </div>
        </div>

        {/* desktop nav end */}
        <div className={`${open ? null : "hidden"} h-[12rem]`}>
          <ul>
            <li className="pb-4 pt-6">
              <NavLink 
                to="/" 
                className={({ isActive }) => 
                  `${isActive ? "active-link pl-8" : "text-white hover:text-cyan-800 font-semibold pl-8 cursor-pointer"}`
                }
                end
              >
                Home
              </NavLink>
            </li>
            <li className="pb-4">
              <NavLink 
                to="/about"
                className={({ isActive }) => 
                  `${isActive ? "active-link pl-8" : "text-white hover:text-cyan-800 font-semibold pl-8 cursor-pointer"}`
                }
              >
                About
              </NavLink>
            </li>
            <li className="pb-4">
              <NavLink 
                to="/contact"
                className={({ isActive }) => 
                  `${isActive ? "active-link pl-8" : "text-white hover:text-cyan-800 font-semibold pl-8 cursor-pointer"}`
                }
              >
                Contact
              </NavLink>
            </li>
            <li className="pb-4">
              <NavLink 
                to="/gallery"
                className={({ isActive }) => 
                  `${isActive ? "active-link pl-8" : "text-white hover:text-cyan-800 font-semibold pl-8 cursor-pointer"}`
                }
              >
                Gallery
              </NavLink>
            </li>
          </ul>
        </div>

      </nav>
    </div>
  );
};

export default Navbar;
