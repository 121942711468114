import Navbar from "../../Navigation/Navbar";
import Footer from "../../Footer/Footer";
import { VscGithubAlt } from 'react-icons/vsc';
import { TfiTwitter } from 'react-icons/tfi';
import { RxInstagramLogo } from 'react-icons/rx';
import AbtMember2 from "../../../images/absedu4.png";
import VisionImg from "../../../images/vision.png";
import MissionImg from "../../../images/mission.png";
import TaRL from "../../../images/TaRL-Africa.png";
import DPrizeLogo from "../../../images/D-Prize-Logo.png";
import manager from "../../../images/manager.png";
import ceo from "../../../images/ceo.png";

const About = () => {
  return (
    <div className="overflow-x-hidden bg-[#e0e0e0]"> {/* Ensure no horizontal scroll */}
      <Navbar />
      <div className="max-w-screen font-poppins">
        {/*<div className="sm:h-screen h-[40rem] sm:mb-0 bg-gradient-to-b from-gray-100/10 to-gray-200/50 pt-20">
          <div className="xl:px-20 px-4 py-2 md:py-12 xl:mx-auto">
            <h1 className="xl:text-2xl md:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-600 text-2xl font-bold leading-snug sm:text-[42px] lg:text-[40px] xl:text-[42px] text-center text-gray-800 sm:mb-4">
              Over 5 Years We Provide Services{" "}
              <br className="md:block hidden" />
              All over Ghana
            </h1>
            <div className="md:mt-14 py-12 sm:flex items-center justify-center relative">
              <img
                src="https://i.ibb.co/KjrPCyW/map.png"
                className="w-full max-w-full xl:h-full h-96 object-cover sm:block hidden"
                alt=""
              />
              <img
                src="https://i.ibb.co/SXKj9Mf/map-bg.png"
                alt=""
                className="sm:hidden -mt-10 block w-full h-96 object-cover absolute z-0"
              />
              <div className="shadow-lg xl:p-6 p-4 sm:w-auto w-full bg-white sm:absolute relative z-20 mt-4 left-0 xl:ml-56 sm:ml-4 xl:-mt-40 sm:-mt-12">
                <p className="text-3xl font-semibold text-gray-800">450+</p>
                <p className="text-base leading-4 xl:mt-4 mt-2 text-gray-600">
                  Learners Helped
                </p>
              </div> 
              <div className="shadow-lg xl:p-6 p-4 sm:w-auto w-full bg-white sm:absolute relative z-20 mt-4 xl:mt-80 sm:mt-56 xl:-ml-0 sm:-ml-12">
                <p className="text-3xl font-semibold text-gray-800">25%</p>
                <p className="text-base leading-4 xl:mt-4 mt-2 text-gray-600">
                  Average Performance Increase
                </p>
              </div>
              <div className="shadow-lg xl:p-6 p-4 sm:w-auto w-full bg-white sm:absolute relative z-20 md:mt-0 sm:-mt-5 mt-4 right-0 xl:mr-56 sm:mr-4">
                <p className="text-3xl font-semibold text-gray-800">70%</p>
                <p className="text-base leading-4 xl:mt-4 mt-2 text-gray-600">
                  Schools Invited Us Back
                </p>
              </div> 
            </div>
          </div>
        </div>*/}

        <div class="flex flex-col mt-16 mx-4 md:mt-32 md:mx-24 md:flex-row gap-4">
          <div class="flex flex-col md:flex-row items-center bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
              <img class="w-full md:w-48 h-96 md:h-full object-cover rounded-t-lg md:rounded-none md:rounded-l-lg" src={MissionImg} alt="" />
              <div class="flex flex-col justify-between p-4 leading-normal">
                  <h5 class="mb-2 text-xl text-center font-bold tracking-tight text-gray-900 dark:text-white">Our Mission</h5>
                  <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">To unlock the Ghanaian Potential through quality foundational literacy and numeracy skills.</p>
              </div>
          </div>

          <div class="flex flex-col md:flex-row-reverse items-center bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
              <img class="w-full md:w-48 h-96 md:h-full object-cover rounded-t-lg md:rounded-none md:rounded-r-lg" src={VisionImg} alt="" />
              <div class="flex flex-col justify-between p-4 leading-normal">
                  <h5 class="mb-2 text-xl text-center font-bold tracking-tight text-gray-900 dark:text-white">Our Vision</h5>
                  <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">We envision a Ghana where every child, regardless of background, has access to quality education, laying the foundation for lifelong success and creating a literate, numerate, and empowered generation.</p>
              </div>
          </div>
        </div>

        {/* teams section */}
        <div className="md:pt-12">
          <div className="container flex justify-center md:mt-12 lg:mt-8 mx-auto pt-16">
            <h1 className="xl:text-3xl text-3xl text-center text-gray-800 font-extrabold sm:w-4/6 w-5/6 mx-auto">
              The Talented People Behind the Scenes that make it all Work!
            </h1>
          </div>
          <div className="w-full bg-gradient-to-b from-gray-100/10 to-gray-200/50 px-10 pt-10">
            <div className="container mx-auto">
              <div className="lg:flex md:flex sm:flex items-center xl:justify-between lg:justify-between flex-wrap md:justify-between sm:justify-around">
                {/* Team Member 1 */}
                <div className="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 lg:ml-2 sm:mb-24 xl:max-w-sm lg:w-2/5">
                  <div className="rounded overflow-hidden shadow-md bg-white">
                    <div className="absolute -mt-20 w-full flex justify-center">
                      <div className="h-32 w-32">
                        <img
                          src={ceo}
                          alt="#"
                          className="rounded-full object-cover h-full w-full shadow-md"
                        />
                      </div>
                    </div>
                    <div className="px-6 mt-16">
                      <div className="font-bold text-xl text-center pb-1">
                        Victor Annani Togborlo
                      </div>
                      <p className="text-gray-800 text-sm text-center">
                        Founder & CEO 
                      </p>
                      <p className="text-justify leading-relaxed text-base tracking-wide text-gray-600 pt-3 font-normal">
                      As the Founder and CEO of Dreams Anchor Institute, Victor leads the strategic vision and operations of the organization, driving initiatives aimed at improving literacy and numeracy in underserved primary schools across Ghana. With a strong commitment to educational equity, Victor fosters partnerships, oversees program implementation, and ensures that the Teaching at the Right Level (TaRL) approach is effectively integrated into the learning environment. Through visionary leadership, Victor aims to unlock the potential of every child, creating lasting change in their educational journey.
                      </p>
                      <div className="w-full flex justify-center pt-5 pb-5">
                        <a href="/" className="mx-5">
                          <VscGithubAlt
                            size={25}
                            className="text-gray-500 hover:text-purple-500"
                          />
                        </a>
                        <a href="/" className="mx-5">
                          <TfiTwitter
                            size={25}
                            className="text-gray-500 hover:text-purple-500"
                          />
                        </a>
                        <a href="/" className="mx-5">
                          <RxInstagramLogo
                            size={25}
                            className="text-gray-500 hover:text-purple-500"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

               
                <div className="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                    <div className="rounded overflow-hidden shadow-md bg-white">
                      <div className="absolute -mt-20 w-full flex justify-center">
                        <div className="h-32 w-32">
                          <img
                            src={manager}
                            alt="alt"
                            className="rounded-full object-cover h-full w-full shadow-md"
                          />
                        </div>
                      </div>
                      <div className="px-6 mt-16">
                        <div className="font-bold text-xl text-center pb-1">
                          Stephen Antwi
                        </div>
                        <p className="text-gray-800 text-sm text-center">
                          General Manager
                        </p>
                        <p className="text-justify leading-relaxed text-base tracking-wide text-gray-600 pt-3 font-normal">
                        The General Manager of Dreams Anchor Institute plays a pivotal role in coordinating daily operations and supporting the execution of educational programs. Stephen collaborates closely with Victor and the teaching staff to ensure that project objectives are met efficiently and effectively. By managing resources, facilitating communication, and monitoring program outcomes, the General Manager helps create a nurturing environment where both learners and educators thrive.
                        </p>
                        <div className="w-full flex justify-center pt-5 pb-5">
                          <a href="/" className="mx-5">
                            <div>
                              <VscGithubAlt
                                size={25}
                                className="text-gray-500 hover:text-purple-500"
                              />
                            </div>
                          </a>
                          <a href="/" className="mx-5">
                            <div>
                              <TfiTwitter
                                size={25}
                                className="text-gray-500 hover:text-purple-500"
                              />
                            </div>
                          </a>
                          <a href="/" className="mx-5">
                            <div>
                              <RxInstagramLogo
                                size={25}
                                className="text-gray-500 hover:text-purple-500"
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                </div>
                 {/* Team Member 2 */}
                 <div className="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                    <div className="rounded overflow-hidden shadow-md bg-white">
                      {/* Image takes the entire top portion */}
                      <div className="w-full h-64">
                        <img
                          src={AbtMember2}
                          alt="Mentor"
                          className="object-cover w-full h-full"
                        />
                      </div>
                      <div className="px-6 mt-4">
                        <div className="font-bold text-xl text-center pb-1">Mentors Team</div>
                        <p className="text-justify leading-relaxed text-base tracking-wide text-gray-600 pb-8 pt-3 font-normal">
                          The team of mentors at Dreams Anchor Institute is dedicated to nurturing and guiding both educators and students in their learning journeys. Each mentor brings a wealth of experience in session observations, evidence-based and interactive coaching sessions to support instructor’s growth and achievement of professional and personal development goals.
                        </p>
                       
                      </div>
                    </div>
                  </div>

              </div>
            </div>
          </div>
        </div>
        {/* teams section end */}
   
        <div class="flex flex-col mx-4 mb-8 p-8 bg-[#f0efef] md:mt-12 md:mx-12">
          <div class="flex flex-col justify-center p-4 leading-normal text-center">
              <h5 class="mb-2 text-xl text-center font-bold tracking-tight text-gray-900 dark:text-white">
              Our Supporters
              </h5>
              <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              We are grateful to our partners and sponsors who share our vision of a better future through education. 
              Special thanks to organizations like D-Prize and TaRL Africa for their support in bringing our vision to life.
              </p>
          </div>

          <div class="flex flex-row justify-center gap-4">
              <img class="w-1/2 md:w-1/3 object-cover" src={DPrizeLogo} alt="" />
              <img class="w-1/2 md:w-1/3 object-cover" src={TaRL} alt="" />
          </div>
        </div>

        <div className="w-[70%] h-[2px] bg-gradient-to-r from-emerald-400 to-lime-800 mx-auto"></div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
